import React, { useState } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import useStaticModule from '@hooks/useStaticModule'
import Img from 'gatsby-image/withIEPolyfill'

import { makeStyles } from '@material-ui/core/styles'

import Container from '@objects/container'
import Stage from '@components/stage'
import Paginator from '@objects/pagination'
import Copy from '@objects/copy'
import PaginationItem from '@objects/paginationItem'
import Icon from '@objects/icon'

const useStyles = makeStyles((theme) => ({
  itemsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  itemRoot: {
    width: '100%',
    marginTop: theme.spacing(7),
    '&:hover $teaserImg , &:focus $teaserImg': {
      transform: 'scale(1.05)',
    },
    '&:nth-child(1)': {
      marginTop: '0',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '460px',
      width: 'calc(50% - 15px)',
      '&:nth-child(2)': {
        marginTop: '0',
      },
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  teaserImg: {
    width: '100%',
    height: 'auto',
    maxHeight: 'none',
    flexShrink: 0,
    transition: 'transform 200ms cubic-bezier(0.4, 0, 0.2, 1)',
    boxShadow: theme.customShadow.topicTeaser,

    [theme.breakpoints.up('md')]: {
      width: '184px',
      height: '184px',
      maxHeight: '184px',
    },
  },
  information: {
    marginLeft: 0,
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(5),
    },
  },
  tagline: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 500,
    color: '#ab1b33',
    marginTop: theme.spacing(2),

    [theme.breakpoints.up('md')]: {
      marginTop: 0,
    },
  },
  headline: {
    marginTop: '10px',
    fontSize: '20px',
    lineHeight: '26px',
    fontWeight: 500,
  },
  externalIcon: {
    fontSize: '16px',
    verticalAlign: 'middle',
    marginLeft: '5px',
  },
  copy: {
    marginTop: theme.spacing(3),
    fontSize: '14px',
    lineHeight: '22px',
    color: theme.palette.grey.dark,
  },
  pagination: {
    marginTop: theme.spacing(15),
  },
}))

function InstagramPage({ pageContext, data }) {
  const classes = useStyles()
  const [displayIndex, setDisplayIndex] = useState(0)
  const { getStaticFieldValue } = useStaticModule(pageContext.modules)
  const stage = {
    headline: getStaticFieldValue(
      'instagram.stage',
      `instagram.stage.headline`
    ),
    copy: getStaticFieldValue('instagram.stage', `instagram.stage.copy`),
  }

  const teaserItemListData = get(data, 'contentfulTeaserItemList')
  const items = teaserItemListData.teaserItems
  const itemsToDisplay = 12

  function isURLExternal(url) {
    if (!!!url) {
      return false
    }
    return url.match(/^((http[s]?|ftp|mailto|tel|fax|javascript):)/)
  }

  function renderItems() {
    const renderItems = []
    const limit = Math.min(items.length, displayIndex + itemsToDisplay)
    for (let idx = displayIndex; idx < limit; idx++) {
      const item = items[idx]
      const renderItem = (
        <div className={classes.wrapper}>
          <Img
            className={classes.teaserImg}
            fluid={{
              ...item.thumbnail?.fluid,
              media: `(min-width: 0px)`,
              aspectRatio: 1,
            }}
            backgroundColor={true}
            alt={item.thumbnail?.description || ''}
          />
          <div className={classes.information}>
            <Copy className={classes.tagline}>{item.tagline}</Copy>
            <Copy className={classes.headline}>
              {item.headline}
              {isURLExternal(item.url) && (
                <Icon
                  className={classes.externalIcon}
                  name={'TextExternalArrow'}
                  size="inherit"
                />
              )}
            </Copy>
            <Copy
              truncate={32}
              className={classes.copy}
              html={item.copy?.childMarkdownRemark?.html}
            />
          </div>
        </div>
      )
      if (item.url) {
        if (isURLExternal(item.url)) {
          renderItems.push(
            <div key={`${item.id}_${idx}`} className={classes.itemRoot}>
              <a
                href={item.url}
                // eslint-disable-next-line no-script-url
                target={item.url.includes('javascript:') ? null : '_blank'}
                rel="noopener noreferrer"
              >
                {renderItem}
              </a>
            </div>
          )
        } else {
          renderItems.push(
            <div key={`${item.id}_${idx}`} className={classes.itemRoot}>
              <Link to={item.url}>{renderItem}</Link>
            </div>
          )
        }
      } else {
        renderItems.push(
          <div key={`${item.id}_${idx}`} className={classes.itemRoot}>
            {renderItem}
          </div>
        )
      }
    }
    return renderItems
  }

  function renderPagination(item) {
    return <PaginationItem {...item} />
  }

  function onPaginationChange(event, page) {
    setDisplayIndex(itemsToDisplay * (page - 1))
  }

  return (
    <>
      <Stage
        alignTop={true}
        type={'generic'}
        title={stage.headline}
        ariaLabel={stage.headline}
        copy={stage.copy}
      />
      <Container ariaLabel="arialabel.instagram">
        {<div className={classes.itemsContainer}>{renderItems()}</div>}
        <Paginator
          className={classes.pagination}
          count={Math.ceil(items.length / itemsToDisplay)}
          renderItem={renderPagination}
          onChange={onPaginationChange}
        />
      </Container>
    </>
  )
}

InstagramPage.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
  modules: PropTypes.array,
}

export default InstagramPage

export const pageQuery = graphql`
  query InstagramPageQuery($locale: String!) {
    contentfulTeaserItemList(
      identifier: { eq: "instagram" }
      node_locale: { eq: $locale }
    ) {
      teaserItems {
        id
        tagline
        headline
        copy {
          childMarkdownRemark {
            html
          }
        }
        thumbnail {
          fluid(quality: 80, maxWidth: 600) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          description
        }
        url
      }
    }
  }
`
